import * as React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { generateUrl } from "../utils/generateUrl";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { PropTypes } from 'prop-types';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

// markup
const NotFoundPage = ({ data, pageContext }) => {
  const { t } = useTranslation();

  const lang = pageContext.language;
  const alternateLanguages = [];
  const label = t("Pagina niet gevonden");

  const activeDoc = {
    lang,
    alternateLanguages,
  };

  return (
    <Layout activeDocMeta={activeDoc} themes={data.allPrismicTheme}>
      <div className="o-wrapper">
        <a className="o-routing-link -back-on-hover" href={generateUrl(lang, "")}>{t("Home")}</a>
        <h1 className="c-intro-block__title">{label}</h1>
      </div>
      <div className="o-wrapper">
        <div className="c-grid-block o-2-col">
          <div className="c-custom-block">
            <div className="clearfix text-formatted field field--name-field-custom-block-body field--type-text-long field--label-hidden field__item">
              <div className="media media--type-image media--view-mode-default">
                <img src="/error-search-manMetDas_up.svg" className="no-image-style rs_skip large" alt={label}/>
                <figcaption className="c-content-block__caption">VDAB</figcaption>
              </div>
              <h3>{t("Sorry, we kunnen deze pagina niet terugvinden")}</h3>
              <p>
                {t("De pagina bestaat niet meer of is verhuisd.")}
                <br />
                {t("Ga naar de")}{" "}
                <a className="linktxt" href={generateUrl(pageContext.language, "")}>{t("homepagina")}</a>
								{" "}
                {t("of gebruik onze zoekfunctie.")}
              </p>
            </div>
          </div>

          <div className="c-contentblock">
            <div className="clearfix text-formatted field field--name-field-contentblok-body field--type-text-long field--label-hidden field__item">
              <div className="media media--type-image media--view-mode-default">
                <img src="/error-search-manMetDas-down.svg" className="no-image-style rs_skip large" alt={label}/>
                <figcaption className="c-content-block__caption">VDAB</figcaption>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

NotFoundPage.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object
}

export default withPrismicUnpublishedPreview(NotFoundPage);

export const query = graphql`
  query NotFoundQuery($locale: String) {
    allPrismicTheme(
      filter: { lang: { eq: "nl-be" } }
      sort: { fields: data___weight, order: ASC }
    ) {
      nodes {
        id
        data {
          title {
            text
          }
        }
        prismicId
        url
      }
    }
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
